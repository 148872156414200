import React from 'react';
import '../../App.css';
import Gallery from './Gallery';

const Receiver = () => {
  return (
    <>
      <Gallery />
    </>
  );
}

export default Receiver;
