import React from 'react';
import '../../../App.css';
import Gallery from '../Gallery';

const  Help=() => {
  return (
   <>
  <Gallery/>
   </>
  );
}

export default Help;
