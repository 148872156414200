import React from 'react';
import Main from './Component/setup/routes-manager/Main';


const App = () => {
  return (
    <>
      <Main/>
      
    </>
  );
}

export default App;
