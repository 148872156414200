import React from 'react';
import '../../../App.css';
import Gallery from '../Gallery';

const  DonorNear=() => {
  return (
   <>
  <Gallery/>
   </>
  );
}

export default DonorNear;
