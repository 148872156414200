import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate,Link } from 'react-router-dom';
import Navbar from '../../Component/layout/Navbar';
import '../../App.css';

 const handleCancel = () => {
   // window.location.href = '/';
 }

const Donor = () => {

   let navigate = useNavigate();

  const initialValues = {
    fname: '',
    contact: '',
    alternate_Contact: '',
    email: '',
    dob: '',
    weight: '',
    gender: '',
    currentAddress: '',
    currentState: '',
    currentCity: '',
    currentPincode: '',
    permanentAddress: '',
    permanentState: '',
    permanentCity: '',
    permanentPincode: '',
    bloodGrp: '',
    donationDate: '',
    six: '',
    consume: '',
    hiv: '',
    msg: '',
}

const validationSchema= Yup.object({
    fname: Yup.string().matches(/^[A-Za-z ]*$/, 'Name should only contain letters and spaces').min(2).max(30).required('Name is required'),
    contact: Yup.string().matches(/^\d{10}$/, 'Contact must be 10 digits').required('Contact is required'),
    alternate_Contact: Yup.string().matches(/^\d{10}$/, 'Contact must be 10 digits'),
    email: Yup.string().email('Invalid Email format').required('Email is required'),
    dob: Yup.date()
    .max(new Date(), 'Date of Birth cannot be in the future')
    .test('is-adult', 'Must be 18 years or older', function (value) {
      const eighteenYearsAgo = new Date();
      eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
      return new Date(value) <= eighteenYearsAgo;
    })
    .required('Date of Birth is required'),
    weight: Yup.number().required('Weight is required'),
    gender: Yup.string().required('Gender is required'),
    currentAddress: Yup.string().required('Current Address is required'),
    currentState: Yup.string().required('Current State is required'),
    currentCity: Yup.string().required('Current City is required'),
    currentPincode: Yup.string().matches(/^[0-9]{6}$/, 'Pincode must be a 6-digit number').required('Pincode is required'),
    permanentAddress: Yup.string().required('Permanent Address is required'),
    permanentState: Yup.string().required('Permanent State is required'),
    permanentCity: Yup.string().required('Permanent City is required'),
    permanentPincode: Yup.string().matches(/^[0-9]{6}$/, 'Pincode must be a 6-digit number').required('Pincode is required'),
    bloodGrp: Yup.string().required('Blood Group is required'),
    donationDate: Yup.date()
      .max(new Date(), 'Donation Date cannot be in the future')
      .test(
        'is-at-least-three-months-ago',
        'Last donation must be at least 3 months ago',
        function (value) {
          const threeMonthsAgo = new Date();
          threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
          return value <= threeMonthsAgo;
        }
      ),
    six: Yup.string(),
    consume: Yup.string().required('Please select an option for alcohol/drug consumption'),
    hiv: Yup.string().required('Please select an option'),
    msg: Yup.string().required('Please provide your medical history if any'),
  });


  const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        // toast.success('Form submitted successfully!');
        const response = await axios.post('https://api.quickblood.org/apptest/donor/add', values)
        toast.success('Form submitted successfully!');
        console.log(response.data);

        resetForm();

        setTimeout(() => {
          navigate('/');
        }, 5000);
      } catch (error) {
        toast.error('Error submitting form. Please try again.');
        console.error('Error submitting form:', error);
      }
    },
  });
 
  const handleClear = () => {
    resetForm();
     
  };

  const handleKeyDownName = (event) => {
  
    const allowedKeys = [8, 46]; // 8 is backspace, 46 is delete
  
    if (
      !(
        (event.keyCode >= 65 && event.keyCode <= 90) ||
        (event.keyCode >= 97 && event.keyCode <= 122) ||
        event.keyCode === 32 ||
        allowedKeys.includes(event.keyCode)
      )
    ) {
      event.preventDefault();
    }
  };

  const handleKeyDown = (event) => {
  
    if ((event.keyCode < 48 || event.keyCode > 57) && event.keyCode !== 8) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className="">
     <Navbar /> 
        <div className="home-donor-form">
          <section className="form-donor">
            <div className='form-center col-md-7'>
              <div className=" overflow-x-hidden">
                <h1 className="h4 fs-2 text-danger text-center pb-4">Registration Form</h1>
                <form className='overflow-hidden' id="myForm" onSubmit={handleSubmit}>
                  <div className="row">
                  <div className="col-md-4 px-3">
          <label htmlFor="validationDefault01" className="form-label">Full Name</label>
          <input
            type="text"
            className={`form-control ${errors.fname && touched.fname ? 'is-invalid' : ''}`}
            id="validationDefault01"
            placeholder="Full Name"
            name="fname"
            value={values.fname}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDownName}
        disabled={values.fname.match(/[^a-zA-Z\s]/)}
            required
          />
          {errors.fname && touched.fname && <div className="invalid-feedback">{errors.fname}</div>}
        </div>

        <div className="col-md-4 px-3">
    <label htmlFor="validationDefault03" className="form-label">Contact</label>
    <input
      type="tel"
      className={`form-control ${errors.contact && touched.contact ? 'is-invalid' : ''}`}
      placeholder="+91"
      id="validationDefault03"
      name="contact"
      value={values.contact}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      required
    />
    {errors.contact && touched.contact && <div className="invalid-feedback">{errors.contact}</div>}
  </div>

  <div className="col-md-4 px-3">
    <label htmlFor="validationDefault04" className="form-label">Alternate Contact</label>
    <input
      type="tel"
      className={`form-control ${errors.alternate_Contact && touched.alternate_Contact ? 'is-invalid' : ''}`}
      placeholder="+91"
      id="validationDefault04"
      name="alternate_Contact"
      value={values.alternate_Contact}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
    
    />
    {errors.alternate_Contact && touched.alternate_Contact && <div className="invalid-feedback">{errors.alternate_Contact}</div>}
  </div>
                  </div>

                  <div className='row g-3 pt-3'>
                  <div className="col-md-4 px-3">
    <label htmlFor="validationDefault05" className="form-label">Email ID</label>
    <input
      type="email"
      className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`}
      id="validationDefault05"
      name="email"
      value={values.email}
      onChange={handleChange}
      onBlur={handleBlur}
      required
    />
    {errors.email && touched.email && <div className="invalid-feedback">{errors.email}</div>}
  </div>

  <div className="col-md-4 px-3">
    <label htmlFor="validationDefault01" className="form-label">Birth Date</label>
    <input
      type="date" placeholder='dd-mm-yyyy'
      className={`form-control ${errors.dob && touched.dob ? 'is-invalid' : ''}`}
      id="validationDefault01"
      name="dob"
      value={values.dob}
      onChange={handleChange}
      onBlur={handleBlur}
      required
    />
    {errors.dob && touched.dob && <div className="invalid-feedback">{errors.dob}</div>}
  </div>

  <div className="col-md-4 px-3">
    <label htmlFor="validationDefault04" className="form-label">Gender</label>
    <select
      className={`form-select ${errors.gender && touched.gender ? 'is-invalid' : ''}`}
      id="validationDefault04"
      name="gender"
      value={values.gender}
      onChange={handleChange}
      onBlur={handleBlur}
      required
    >
      <option value="" disabled>Select</option>
      <option value="Male">Male</option>
      <option value="Female">Female</option>
      <option value="Other">Other</option>
    </select>
    {errors.gender && touched.gender && <div className="invalid-feedback">{errors.gender}</div>}
  </div>

  <div className="col-md-4 px-3">
    <label htmlFor="validationDefault04" className="form-label">Blood Group</label>
    <select
      className={`form-select ${errors.bloodGrp && touched.bloodGrp ? 'is-invalid' : ''}`}
      id="validationDefault04"
      name="bloodGrp"
      value={values.bloodGrp}
      onChange={handleChange}
      onBlur={handleBlur}
      required
    >
      <option value="" disabled>Choose</option>
      <option value="AB+">AB+</option>
      <option value="AB-">AB-</option>
      <option value="A+">A+</option>
      <option value="A-">A-</option>
      <option value="B+">B+</option>
      <option value="B-">B-</option>
      <option value="O+">O+</option>
      <option value="O-">O-</option>
    </select>
    {errors.bloodGrp && touched.bloodGrp && <div className="invalid-feedback">{errors.bloodGrp}</div>}
  </div>

  <div className="col-md-4 px-3">
    <label htmlFor="validationDefault05" className="form-label">Weight (kg)</label>
    <input
      type="text"
      className={`form-control ${errors.weight && touched.weight ? 'is-invalid' : ''}`}
      id="validationDefault05"
      name="weight"
      value={values.weight}
      onChange={handleChange}
      onBlur={handleBlur}
      required
    />
    {errors.weight && touched.weight && <div className="invalid-feedback">{errors.weight}</div>}
  </div>
</div>

<div className="row">
  <div className="col-md-6 mt-3 px-3">
    <label htmlFor="validationCustom03">current Address</label>
    <input
      type="text"
      className={`form-control ${errors.currentAddress && touched.currentAddress ? 'is-invalid' : ''}`}
      id="validationCustom03"
      name="currentAddress"
      value={values.currentAddress}
      onChange={handleChange}
      onBlur={handleBlur}
      required
    />
    {errors.currentAddress && touched.currentAddress && <div className="invalid-feedback">{errors.currentAddress}</div>}

    <div className="row py-3">
      <div className="col-md-12">
        <label htmlFor="stateDropdown" className="form-label">State:</label>
        <select
          className='form-select'
          id="stateDropdown"
          name='currentState'
          value={values.currentState}
          onChange={handleChange}
          onBlur={handleBlur}
          required
        >
                            <option value="" className='px-3'>Select state</option>
                            <option value="Andhra Pradesh">Andhra Pradesh</option>
                            <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                            <option value="Assam">Assam</option>
                            <option value="Bihar">Bihar</option>
                            <option value="Chhattisgarh">Chhattisgarh</option>
                            <option value="Goa">Goa</option>
                            <option value="Gujarat">Gujarat</option>
                            <option value="Haryana">Haryana</option>
                            <option value="Himachal Pradesh">Himachal Pradesh</option>
                            <option value="Jharkhand">Jharkhand</option>
                            <option value="Karnataka">Karnataka</option>
                            <option value="Kerala">Kerala</option>
                            <option value="Madhya Pradesh">Madhya Pradesh</option>
                            <option value="Maharashtra">Maharashtra</option>
                            <option value="Manipur">Manipur</option>
                            <option value="Meghalaya">Meghalaya</option>
                            <option value="Mizoram">Mizoram</option>
                            <option value="Nagaland">Nagaland</option>
                            <option value="Odisha">Odisha</option>
                            <option value="Punjab">Punjab</option>
                            <option value="Rajasthan">Rajasthan</option>
                            <option value="Sikkim">Sikkim</option>
                            <option value="Tamil Nadu">Tamil Nadu</option>
                            <option value="Telangana">Telangana</option>
                            <option value="Tripura">Tripura</option>
                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                            <option value="Uttarakhand">Uttarakhand</option>
                            <option value="West Bengal">West Bengal</option>
                          </select>
                        </div>
                        <div className="col-md-6 mt-mobile">
        <label htmlFor="validationDefault04" className="form-label">City</label>
        <input
          type="text"
          className={`form-control ${errors.currentCity && touched.currentCity ? 'is-invalid' : ''}`}
          id="validationPincode"
          name='currentCity'
          value={values.currentCity}
          onChange={handleChange}
          onBlur={handleBlur}
          required
        />
        {errors.currentCity && touched.currentCity && <div className="invalid-feedback">{errors.currentCity}</div>}
      </div>
      <div className="col-md-6">
        <label htmlFor="validationPincode" className="form-label">Pincode</label>
        <input
          type="text"
          className={`form-control ${errors.currentPincode && touched.currentPincode ? 'is-invalid' : ''}`}
          id="validationPincode"
          name='currentPincode'
          value={values.currentPincode}
          onChange={handleChange}
          onBlur={handleBlur}
          required
        />
        {errors.currentPincode && touched.currentPincode && <div className="invalid-feedback">{errors.currentPincode}</div>}
      </div>
    </div>
  </div>
                
<div className="col-md-6 my-3 px-3">
  <label htmlFor="validationCustom03">permanent Address</label>
  <input
    type="text"
    className={`form-control ${errors.permanentAddress && touched.permanentAddress ? 'is-invalid' : ''}`}
    id="validationCustom03"
    name='permanentAddress'
    value={values.permanentAddress}
    onChange={handleChange}
    onBlur={handleBlur}
    required
  />
  {errors.permanentAddress && touched.permanentAddress && <div className="invalid-feedback">{errors.permanentAddress}</div>}

  <div className="row pt-3">
    <div className="col-md-12">
      <label htmlFor="stateDropdown" className="form-label">State:</label>
      <select
        className='form-select'
        id="stateDropdown"
        name='permanentState'
        value={values.permanentState}
        onChange={handleChange}
        onBlur={handleBlur}
        required
      >
                            <option value="" className='px-3'>Select  state</option>
                            <option value="Andhra Pradesh">Andhra Pradesh</option>
                            <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                            <option value="Assam">Assam</option>
                            <option value="Bihar">Bihar</option>
                            <option value="Chhattisgarh">Chhattisgarh</option>
                            <option value="Goa">Goa</option>
                            <option value="Gujarat">Gujarat</option>
                            <option value="Haryana">Haryana</option>
                            <option value="Himachal Pradesh">Himachal Pradesh</option>
                            <option value="Jharkhand">Jharkhand</option>
                            <option value="Karnataka">Karnataka</option>
                            <option value="Kerala">Kerala</option>
                            <option value="Madhya Pradesh">Madhya Pradesh</option>
                            <option value="Maharashtra">Maharashtra</option>
                            <option value="Manipur">Manipur</option>
                            <option value="Meghalaya">Meghalaya</option>
                            <option value="Mizoram">Mizoram</option>
                            <option value="Nagaland">Nagaland</option>
                            <option value="Odisha">Odisha</option>
                            <option value="Punjab">Punjab</option>
                            <option value="Rajasthan">Rajasthan</option>
                            <option value="Sikkim">Sikkim</option>
                            <option value="Tamil Nadu">Tamil Nadu</option>
                            <option value="Telangana">Telangana</option>
                            <option value="Tripura">Tripura</option>
                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                            <option value="Uttarakhand">Uttarakhand</option>
                            <option value="West Bengal">West Bengal</option>
                          </select>
                        </div>
                        <div className="col-md-6 mt-mobile">
      <label htmlFor="validationDefault04" className="form-label">City</label>
      <input
        type="text"
        className={`form-control ${errors.permanentCity && touched.permanentCity ? 'is-invalid' : ''}`}
        id="validationPincode"
        name='permanentCity'
        value={values.permanentCity}
        onChange={handleChange}
        onBlur={handleBlur}
        required
      />
      {errors.permanentCity && touched.permanentCity && <div className="invalid-feedback">{errors.permanentCity}</div>}
    </div>
    <div className="col-md-6">
      <label htmlFor="validationPincode" className="form-label">Pincode</label>
      <input
        type="text"
        className={`form-control ${errors.permanentPincode && touched.permanentPincode ? 'is-invalid' : ''}`}
        id="validationPincode"
        name='permanentPincode'
        value={values.permanentPincode}
        onChange={handleChange}
        onBlur={handleBlur}
        required
      />
      {errors.permanentPincode && touched.permanentPincode && <div className="invalid-feedback">{errors.permanentPincode}</div>}
    </div>
  </div>
</div>
                  </div>


                  <div className="row g-3">
  <div className="col-md-4 px-3">
    <label htmlFor="validationDefaultUsername" className="form-label">Recently Donated</label>
    <input
      type="date"
      className={`form-control ${errors.donationDate && touched.donationDate ? 'is-invalid' : ''}`}
      id="validationDefaultUsername"
      aria-describedby="inputGroupPrepend2"
      name="donationDate"
      value={values.donationDate}
      onChange={handleChange}
      onBlur={handleBlur}
    />
    {errors.donationDate && touched.donationDate && <div className="invalid-feedback">{errors.donationDate}</div>}
  </div>

  <div className="col-md-4 px-md-4">
    <p>Do you consume Alcohol/Drugs?</p>
    <div className="form-check form-check-inline">
      <input
        className="form-check-input"
        type="radio"
        id="inlineCheckbox1"
        name="consume"
        value="true"
        checked={values.consume === 'true'}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <label className="form-check-label" htmlFor="inlineCheckbox1">
        Yes
      </label>
    </div>
    <div className="form-check form-check-inline">
      <input
        className="form-check-input"
        type="radio"
        id="inlineCheckbox2"
        name="consume"
        value="false"
        checked={values.consume === 'false'}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <label className="form-check-label" htmlFor="inlineCheckbox2">
        No
      </label>
    </div>
  </div>

  <div className="col-md-4 px-md-4">
    <p>Have you ever tested HIV Positive?</p>
    <div className="form-check form-check-inline">
      <input
        className="form-check-input"
        type="radio"
        id="inlineCheckbox3"
        name="hiv"
        value="Yes"
        checked={values.hiv === 'Yes'}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <label className="form-check-label" htmlFor="inlineCheckbox3">
        Yes
      </label>
    </div>
    <div className="form-check form-check-inline">
      <input
        className="form-check-input"
        type="radio"
        id="inlineCheckbox4"
        name="hiv"
        value="false"
        checked={values.hiv === 'false'}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <label className="form-check-label" htmlFor="inlineCheckbox4">
        No
      </label>
    </div>
  </div>
</div>
                  <div className="row pt-2">
                  <div className="col-md-6 mt-2">
    <p>In the last six months, have you had any of the following?</p>
    <div className="form-check form-check-inline">
      <input
        className="form-check-input"
        type="radio"
        id="inlineRadio1"
        name="six"
        value='Tattooing'
        checked={values.six === 'Tattooing'}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <label className="form-check-label" htmlFor="inlineRadio1">
        Tattooing
      </label>
    </div>
    <div className="form-check form-check-inline">
      <input
        className="form-check-input"
        type="radio"
        id="inlineRadio2"
        name="six"
        value="Piercing"
        checked={values.six === 'Piercing'}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <label className="form-check-label" htmlFor="inlineRadio2">
        Piercing
      </label>
    </div>
    <div className="form-check form-check-inline">
      <input
        className="form-check-input"
        type="radio"
        id="inlineRadio3"
        name="six"
        value="Other"
        checked={values.six === 'Other'}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <label className="form-check-label" htmlFor="inlineRadio3">
        Other
      </label>
    </div>
  </div>
  <div className="col-md-6">
  <div className="form-group shadow-textarea mt-mobile">
    <label htmlFor="exampleFormControlTextarea6">Your medical history if any?</label>
    <textarea
      className={`form-control mt-2 ${errors.msg && touched.msg ? 'is-invalid' : ''}`}
      id="exampleFormControlTextarea6"
      rows="2"
      placeholder="Sugar, Hemoglobin, BP, Cancer"
      name="msg"
      value={values.msg}
      onChange={handleChange}
      onBlur={handleBlur}
      required
    ></textarea>
    {errors.msg && touched.msg && <div className="invalid-feedback">{errors.msg}</div>}
  </div>
</div>

    </div>


                  <div className="text-center">
                    <button type="submit" className="btn btn-secondary mt-4" style={{ marginRight: '20px' }}>Submit Form</button>
                    <button type="button" className="btn btn-secondary mt-4" onClick={handleClear}>
                      Clear Form
                    </button>
                  </div>
                </form>

                <div className="cancel-btn overflow-hidden" style={{ position: 'absolute', top: '3%', left: '95%', transform: 'translate(-50%, -50%)' }} >
                  <Link to="/" onClick={handleCancel}>
                    <i class="fa-solid fa-xmark fa-sxl" style={{ color: '#26282b' }}></i>
                  </Link> 
                </div>
                <ToastContainer />
              </div >
            </div >
          </section>
        </div>
      </div>
    </>
  );
};

export default Donor;
